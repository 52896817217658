// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-coolharbour-js": () => import("./../src/pages/about-coolharbour.js" /* webpackChunkName: "component---src-pages-about-coolharbour-js" */),
  "component---src-pages-auditor-aggregator-js": () => import("./../src/pages/auditor-aggregator.js" /* webpackChunkName: "component---src-pages-auditor-aggregator-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-management-portal-js": () => import("./../src/pages/data-management-portal.js" /* webpackChunkName: "component---src-pages-data-management-portal-js" */),
  "component---src-pages-data-security-js": () => import("./../src/pages/data-security.js" /* webpackChunkName: "component---src-pages-data-security-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laboratory-information-management-systems-js": () => import("./../src/pages/laboratory-information-management-systems.js" /* webpackChunkName: "component---src-pages-laboratory-information-management-systems-js" */),
  "component---src-pages-learning-management-system-js": () => import("./../src/pages/learning-management-system.js" /* webpackChunkName: "component---src-pages-learning-management-system-js" */),
  "component---src-pages-liveview-js": () => import("./../src/pages/liveview.js" /* webpackChunkName: "component---src-pages-liveview-js" */),
  "component---src-pages-packagingprocess-js": () => import("./../src/pages/packagingprocess.js" /* webpackChunkName: "component---src-pages-packagingprocess-js" */),
  "component---src-pages-training-aggregator-js": () => import("./../src/pages/training-aggregator.js" /* webpackChunkName: "component---src-pages-training-aggregator-js" */)
}

